import Link from "next/link";
import { ROUTES } from "./routes";
import { TextLink } from "../ui/TextLink";

export const TRANSLATION = {
  EN: {
    ACCOUNT_ALREADY_ACTIVATED: (
      <>
        Your account is already activated.{" "}
        <TextLink
          href={ROUTES.EMPLOYEE.LOGIN}
          as={Link}
          variant="underline"
          className="text-rose-600 hover:text-rose-600"
        >
          Please try logging in.
        </TextLink>
      </>
    ),
    ACCOUNT_NOT_ACTIVATED:
      "Your account is not yet activated. Please reach out to our Member Care Team for support: 833-603-3864\n\nSupport is available Monday through Friday between 9 a.m. and 5 p.m. ET.",
    BRAND_TO_GENERIC_TOOLTIP: {
      title: "Brand to Generic",
      content:
        "Generic and brand-name drugs work the same. Generic drugs are just as effective as their branded counterparts. Generic name drugs typically cost about 80% to 85% less than branded drugs.",
    },
    COMBO_SPIT_TOOLTIP: {
      title: "Combo Split",
      content:
        "Combination splitting is a practice of taking a brand medication and splitting into two equivalent generics. Generic drugs are just as effective as their branded counterparts. Generic name drugs typically cost about 80% to 85% less than branded drugs.",
    },
    GENERIC_ERROR: "Unfortunately, an error has occurred.",
    INVALID_CODE: "The code you entered is invalid. Please try again.",
    PILL_SPLIT_TOOLTIP: {
      title: "Pill Split",
      content:
        "Pill-splitting refers to the practice of splitting a tablet or pill to provide a lower dose of the active ingredient, or to obtain multiple smaller doses, either to reduce cost or because the pills available provide a larger dose than required.",
    },
    PRESCRIPTION_NO_OPTIONS: {
      title: "We can't find any savings for this yet",
      content:
        "We'll keep looking for opportunities to save and let you know when we find any.",
    },
    THERAPEUTIC_INTERCHANGE_TOOLTIP: {
      title: "Therapeutic Interchange",
      content:
        "Therapeutic interchange is the act of switching a prescribed drug for another drug in the same therapeutic class that is believed to be therapeutically similar but may be chemically different. Therapeutic interchange is different from generic substitution in that it does not occur between therapeutically equivalent products; instead, products are substituted for those that are likely to have a substantially equivalent therapeutic effect generally at a lower cost. Therapeutic interchange is common in institutional settings across the United States but rarely occurs in community pharmacy settings without a pharmacist first contacting the original prescriber and requesting a new prescription in order to facilitate a change.",
    },
    SHARE_YOUR_BENEFIT_TEXT:
      "Activate your own account with Counter to view your personalized prescription savings opportunities. This is a health benefit I get through my employer that you can use to save money on your prescription medications, too.",
  },
};

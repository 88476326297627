export interface CanaRxEnrollmentFormFlow {
  drugAllergies: CanaRxSelectionOption[];
  hospitalizations: CanaRxSelectionOption[];
  medicalConditions: CanaRxSelectionOption[];
  operations: CanaRxSelectionOption[];
  otherMedications: CanaRxOtherMedication[];
  prescriptionPreferences: CanaRxSelectionOption;
  sign: CanaRxSignature;
  supplements: CanaRxSupplement[];
}

export interface CanaRxOtherMedication {
  dateStarted: string | null;
  daysSupply: string | null;
  dosage: string | null;
  drugName: string;
  frequency: string | null;
  quantity: string | null;
  reason: string | null;
  type: MedicationType;
}

export interface CanaRxSelectionOption {
  label: string;
  value: string;
}

export interface CanaRxSignature {
  signatureImage: string | null;
  signatureText: string | null;
}

export interface CanaRxSupplement {
  dateStarted: string | null;
  dosage: string | null;
  frequency: string | null;
  name: string;
  reason: string | null;
}

export type MailOrderEnrollmentFormFlow = CanaRxEnrollmentFormFlow;

export interface MedicalHistoryFlowValue {
  back: string | null;
  next: string | null;
  useProgressBar: boolean;
}

export type MedicalHistoryFlow = Record<
  MedicalHistoryFlowKey,
  MedicalHistoryFlowValue
>;

export enum MedicalHistoryFlowKey {
  AUTHORIZATION = "authorization",
  DONE = "done",
  DRUG_ALLERGIES = "drug-allergies",
  HOSPITALIZATIONS = "hospitalizations",
  MEDICAL_CONDITIONS = "medical-conditions",
  MEDICAL_HISTORY = "medical-history",
  OPERATIONS = "operations",
  OTHER_MEDICATIONS = "other-medications",
  PRESCRIPTION_PREFERENCES = "prescription-preferences",
  SIGN = "sign",
  START = "start",
  UPLOAD_ID = "upload-id",
}

export enum MedicationType {
  CUSTOM = "custom",
  FROM_DATABASE = "from-database",
}

export interface UpdateMailOrderEnrollmentFormFlow {
  action: string;
  page: string;
  payload: MailOrderEnrollmentFormFlow;
}

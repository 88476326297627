"use client";

import Link from "next/link";
import {
  CSSProperties,
  forwardRef,
  HTMLAttributes,
  MouseEventHandler,
  ReactNode,
  Ref,
  RefObject,
} from "react";
import { CircularSpinner } from "../CircularSpinner";

export const VARIANTS = ["text", "text-white", "underline"] as const;

type VariantTuple = typeof VARIANTS;
type Variant = VariantTuple[number];

export interface TextLinkProps extends HTMLAttributes<HTMLElement> {
  as?: "button" | "div" | "a" | "span";
  children: ReactNode;
  className?: string;
  forwardRef?: RefObject<HTMLElement & HTMLDivElement>;
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick?: MouseEventHandler<
    HTMLButtonElement | HTMLDivElement | HTMLLinkElement | HTMLSpanElement
  >;
  rounded?: boolean;
  style?: CSSProperties;
  target?: null | "_blank";
  type?: "button" | "submit" | "reset";
  variant: Variant;
}

export interface HrefTextLinkProps extends HTMLAttributes<HTMLElement> {
  as: typeof Link;
  children: ReactNode;
  className?: string;
  forwardRef?: RefObject<HTMLElement & HTMLDivElement>;
  href: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick?: MouseEventHandler<HTMLElement>;
  rounded?: boolean;
  style?: CSSProperties;
  variant: Variant;
  target?: null | "_blank";
  tabindex?: string | number;
}

function TextLinkInner(
  {
    as: As = "a",
    children,
    className,
    forwardRef,
    isDisabled,
    isLoading,
    onClick,
    rounded,
    style,
    variant,
    ...rest
  }: TextLinkProps | HrefTextLinkProps,
  ref: Ref<HTMLButtonElement | HTMLDivElement | HTMLLinkElement>,
) {
  function getStyle() {
    if (isLoading) {
      return "cursor-not-allowed text-muted";
    } else {
      switch (variant) {
        case "underline":
          return `text-link-default hover:text-link-hover active:text-link-active underline underline-offset-2 outline-none focus-visible:outline-offset-3 focus-visible:outline-1.5 focus-visible:outline-black`;
        case "text":
          return `text-link-default hover:text-link-hover active:text-link-active outline-none focus-visible:outline-offset-3 focus-visible:outline-1.5 focus-visible:outline-black`;
        case "text-white":
          return `text-white hover:text-white-100 active:text-white-200 outline-none focus-visible:outline-offset-3 focus-visible:outline-1.5 focus-visible:outline-white`;
      }
    }
  }

  return (
    <As
      onClick={onClick}
      className={`inline-flex cursor-pointer items-center justify-center rounded-button font-[500] tracking-tight hover:underline ${getStyle()} ${
        className ?? ""
      }`}
      disabled={isDisabled ?? isLoading}
      // @ts-expect-error: Type '(instance: HTMLDivElement | HTMLButtonElement | HTMLLinkElement | null) => void' is not assignable to type 'string'.
      ref={ref}
      {...rest}
    >
      {children}

      {isLoading && (
        <span className="ml-1.5 inline-grid w-[1.25ch]">
          <CircularSpinner />
        </span>
      )}
    </As>
  );
}

export const TextLink = forwardRef(TextLinkInner);

import styles from "./styles.module.css";

export function CircularSpinner() {
  return (
    <svg
      className={`${styles.circular} aspect-square h-auto w-full max-w-[24px] origin-center stroke-current`}
      viewBox="25 25 50 50"
      width="50"
      height="50"
      aria-label="Loading"
    >
      <circle
        className={styles.path}
        cx="50"
        cy="50"
        r="20"
        fill="none"
        strokeWidth="6"
        strokeMiterlimit="10"
      />
    </svg>
  );
}

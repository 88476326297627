import { ReactNode } from "react";
import { SERVER_ERROR } from "../config/server-error";
import { TRANSLATION } from "../config/translation";

export function formatErrorMessage(
  error: string | string[] | Error | unknown,
): string {
  let formattedError = error;

  if (Array.isArray(error)) {
    formattedError = error.join("\n");
  }

  if (error instanceof Error && !!error.message) {
    formattedError = error.message;
  }

  if (typeof formattedError === "string") {
    switch (formattedError) {
      case SERVER_ERROR.INVALID_TOKEN:
        return TRANSLATION.EN.INVALID_CODE;
      case SERVER_ERROR.MEMBER_IS_UNDERAGE:
        return TRANSLATION.EN.ACCOUNT_NOT_ACTIVATED;
      case SERVER_ERROR.MEMBER_NOT_FOUND:
        return TRANSLATION.EN.ACCOUNT_NOT_ACTIVATED;
      case "":
        return TRANSLATION.EN.GENERIC_ERROR;
      default:
        return formattedError;
    }
  }

  return TRANSLATION.EN.GENERIC_ERROR;
}

export function formatErrorMessageAsJsx(
  error: string | string[] | Error | unknown,
): ReactNode {
  let formattedError = error;

  if (Array.isArray(error)) {
    formattedError = error.join("\n");
  }

  if (error instanceof Error && !!error.message) {
    formattedError = error.message;
  }

  if (typeof formattedError === "string") {
    switch (formattedError) {
      case SERVER_ERROR.INVALID_TOKEN:
        return TRANSLATION.EN.INVALID_CODE;
      case SERVER_ERROR.MEMBER_ALREADY_ACTIVATED:
        return TRANSLATION.EN.ACCOUNT_ALREADY_ACTIVATED;
      case SERVER_ERROR.MEMBER_IS_UNDERAGE:
        return TRANSLATION.EN.ACCOUNT_NOT_ACTIVATED;
      case SERVER_ERROR.MEMBER_NOT_FOUND:
        return TRANSLATION.EN.ACCOUNT_NOT_ACTIVATED;
      case "":
        return TRANSLATION.EN.GENERIC_ERROR;
      default:
        return formattedError;
    }
  }

  return TRANSLATION.EN.GENERIC_ERROR;
}

import styles from "./styles.module.css";

export function HorizontalSpinner() {
  return (
    <div
      aria-label="Loading"
      className={`${styles.root} inline-grid w-full max-w-[40px] auto-cols-fr grid-flow-col gap-1`}
    >
      <div
        className={`${styles.bounce1} aspect-square rounded-full bg-current`}
      ></div>
      <div
        className={`${styles.bounce2} aspect-square rounded-full bg-current`}
      ></div>
      <div
        className={`${styles.bounce3} aspect-square rounded-full bg-current`}
      ></div>
    </div>
  );
}
